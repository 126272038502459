// Overrides CoreUI & Bootstrap Variables

// Bootstrap overrides

//
// Color system
//

$white:                           #fff;
$gray-100:                        #f1f3f8;
$gray-200:                        #e3e8ec;
$gray-300:                        #bcc5d0;
$gray-400:                        #869fac;
$gray-500:                        #678898;
$gray-600:                        #94a0b2;
$gray-700:                        #3e515b;
$gray-800:                        #444d58;
$gray-900:                        #151b1e;
$black:                           #000;

$blue:                            #20a8d8;
$indigo:                          #6610f2;
$purple:                          #6f42c1;
$pink:                            #e83e8c;
$red:                             #f86c6b;
$orange:                          #f8cb00;
$yellow:                          #ffc107;
$green:                           #4dbd74;
$teal:                            #20c997;
$cyan:                            #63c2de;

// Import default variables
@import "node_modules/@coreui/coreui-pro/scss/variables";

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:              true;
$enable-rounded:                  false;

// Body
//
// Settings for the `<body>` element.

$body-bg:                         #eff3f8;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:                  0.875rem;

// Breadcrumbs

$breadcrumb-bg:                   #fff;
$breadcrumb-margin-bottom:        1.5rem;
$breadcrumb-padding-y:            1rem;
$breadcrumb-padding-x:            1.25rem;

// Cards

$card-border-color:               $gray-200;
$card-cap-bg:                     $gray-100;

// Dropdowns

$dropdown-padding-y:              0;
$dropdown-border-color:           $gray-200;
$dropdown-divider-bg:             $gray-100;

// Buttons

$btn-secondary-border:            $gray-300;

// Progress bars

$progress-bg:                     $gray-100;

// Tables

$table-bg-accent:                 $gray-100;
$table-bg-hover:                  $gray-100;

// Forms

$input-group-addon-bg:            $gray-100;
$input-border-color:              $gray-200;
$input-group-addon-border-color:  $gray-200;

// CoreUI overrides

$border-color:                      $gray-200;

// Navbar

$navbar-height:                     60px;
$navbar-brand-width:                220px;

// Sidebar

$sidebar-width:                     220px;
$mobile-sidebar-width:              200px;

// Sidebar Navigation

$sidebar-nav-color:                 #fff;
$sidebar-nav-title-padding-y:       1rem;
$sidebar-nav-title-padding-x:       1.25rem;
$sidebar-nav-link-padding-y:        1rem;
$sidebar-nav-link-padding-x:        1.25rem;

// Aside

$aside-menu-nav-padding-y:          1rem;
$aside-menu-nav-padding-x:          1.25rem;
